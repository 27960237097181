import '../css/photoswipe.css';

import PhotoSwipeLightBox from './photoswipe-lightbox.esm.js';
import PhotoSwipe from './photoswipe.esm.js';

const PSOptions = {
    loop: true,
    padding: {
        top: 50,
        bottom: 50,
        left: 20,
        right: 20,
    },
    bgOpacity: 0.9,
    indexIndicatorSep: ' de ',
    pswpModule: PhotoSwipe,
    children: 'a',
    closeTitle: 'Cerrar',
    zoomTitle: 'Zoom',
};

const PSOptionsNovias = { ...PSOptions };
PSOptionsNovias.gallery = '#gallery-novias';
PSOptionsNovias.mainClass = 'pswp-novias';
const lightboxNovias = new PhotoSwipeLightBox(PSOptionsNovias);
lightboxNovias.init();
